exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-account-verified-jsx": () => import("./../../../src/pages/account-verified.jsx" /* webpackChunkName: "component---src-pages-account-verified-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-get-started-jsx": () => import("./../../../src/pages/get-started.jsx" /* webpackChunkName: "component---src-pages-get-started-jsx" */),
  "component---src-pages-getstarted-html-jsx": () => import("./../../../src/pages/getstarted.html.jsx" /* webpackChunkName: "component---src-pages-getstarted-html-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-installation-error-html-jsx": () => import("./../../../src/pages/installation-error.html.jsx" /* webpackChunkName: "component---src-pages-installation-error-html-jsx" */),
  "component---src-pages-installation-error-jsx": () => import("./../../../src/pages/installation-error.jsx" /* webpackChunkName: "component---src-pages-installation-error-jsx" */),
  "component---src-pages-installation-success-html-jsx": () => import("./../../../src/pages/installation-success.html.jsx" /* webpackChunkName: "component---src-pages-installation-success-html-jsx" */),
  "component---src-pages-installation-success-jsx": () => import("./../../../src/pages/installation-success.jsx" /* webpackChunkName: "component---src-pages-installation-success-jsx" */),
  "component---src-pages-no-active-account-jsx": () => import("./../../../src/pages/no-active-account.jsx" /* webpackChunkName: "component---src-pages-no-active-account-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-purchase-success-jsx": () => import("./../../../src/pages/purchase-success.jsx" /* webpackChunkName: "component---src-pages-purchase-success-jsx" */),
  "component---src-pages-success-jsx": () => import("./../../../src/pages/success.jsx" /* webpackChunkName: "component---src-pages-success-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */)
}

